import React, { Component } from "react";

import "semantic-ui-css/semantic.min.css";

import { Container, Header } from "semantic-ui-react";

import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Container>
          <Header dividing as="h1" size="huge">
            Bot's Workshop
          </Header>
          <p className="lead">
            I am still exploring on what I should build here. But while I figure
            that out, this is just a barebone template page.
          </p>
          <p className="lead">
            Hope to see you again after I start building something here. Till
            then, have a good day.
          </p>
        </Container>
        <footer>
          <Container>
            <p>
              In case you have a good idea, please email me at
              hello@botonquest.com
            </p>
          </Container>
        </footer>
      </div>
    );
  }
}

export default App;
